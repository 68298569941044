import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import SEO from '../components/seo/seo'


const IndexPage = () => {

  const data = useStaticQuery(graphql`
  query {
    imageOne: file(relativePath: { eq: "house-for-sale.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 90) {
            ...GatsbyImageSharpFluid
        }
      }
    }
    imageTwo: file(relativePath: { eq: "small-house-and-keys.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300, quality: 90) {
            ...GatsbyImageSharpFluid
        }
      }
    }
    imageThree: file(relativePath: { eq: "pleasant-title-logo.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 120, quality: 90) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFluid
        }
      }
    }
  }
`)

return (
    <>
      <SEO
        title="Home"
        description="Pleasant Street Title & Closing is a full service title company offering services for brokered and non brokered real estate transactions. Helping buyers, sellers and lenders including closings, title searches, title insurance, mortgage discharges and deeds by experienced real estate professionals overseen by Attorney James G. Feleen, Esq."
        keywords={['pleasant street', 'pleasant street title & closing', 'title', 'title insurance', 'title search', 'closing', 'closings', 'buyer', 'buyers', 'seller', 'sellers', 'lender', 'lenders', 'real estate', 'realtor', 'broker', 'mortgage', 'real estate lawyer', 'real estate attorney', 'law', 'lawyer', 'law office', 'attorney', 'James Feleen', 'Jim Feleen', 'Claremont', 'NH', 'New Hampshire', 'Claremont NH', 'sullivan county', 'grafton county', 'cheshire county', 'merrimack county', 'Acworth', 'Charlestown', 'Cornish', 'Croydon', 'Goshen', 'Grantham', 'Langdon', 'Lempster', 'Newport', 'Plainfield', 'Springfield', 'Sunapee', 'Unity', 'Washington']}/>
      <div className="body-wrapper">
        <div className="mobile-logo-container">
          <div className="mobile-logo">
            <Img alt="Pleasant Title Logo" fluid={data.imageThree.childImageSharp.fluid}/>
          </div>
          <div className="mobile-navbar_logo">
            <h1><Link to="/">Pleasant Street Title & Closing</Link></h1>
            <h4>Serving West Central New Hampshire</h4>
          </div>
        </div>
      <Img alt="House with for sale sign" className="hero-image rounded" fluid={data.imageOne.childImageSharp.fluid}/>
        <h2>WELCOME</h2>
        <p>Pleasant Street Title and Closing, LLC is a full service real estate conveyancing firm offering a wide range of services for sellers, buyers, lenders or brokers. Whether it is the sale of a single family home or a complex commercial transaction, we provide competent, timely and cost-effective services. We are ready, willing and able to assist at whatever level you select to produce a successful transaction. </p>
        <div className="services">
          <div className="service-list">
          <h3>We Handle:</h3>
          <ul>
            <li>Purchase and Sale Agreements</li>
            <li>Deed Preparation</li>
            <li>Title Searches</li>
            <li>Witness Closings</li>
            <li>Title Insurance</li>
            <li>Document Preparation</li>
            <li>All Types of Closings</li>
            <li>Recording</li>
          </ul>
          </div>
          <Img alt="House with for sale sign" className="body-image rounded" fluid={data.imageTwo.childImageSharp.fluid}/>
        </div>
        <p>Pleasant Street Title and Closing is affiliated with the <a href="https://www.feleenlaw.com">Law Office of James G. Feleen, PLLC</a>.  Attorney Feleen personally oversees and supervises every transaction. His 26 years of experience as a real estate lawyer guarantees successful results.</p>
      </div>
    </>
  )
}

export default IndexPage